import React, { Component } from "react"

export default class PlansFeatures extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActiveTab: this.props.plans.oceanPlanContent.name,
      colSpan: "",
    }
  }

  handleClick = (tabName) => {
    this.setState({ isActiveTab: tabName })
  }

  componentDidMount() {
    this.setState({ colSpan: window.innerWidth <= 767 ? 2 : 4 })
  }

  render() {
    const data = this.props

    const plansContent = [data.plans.beachPlanContent, data.plans.seaPlanContent, data.plans.oceanPlanContent]

    const planImages = this.props.planImages

    return (
      <div name={data.scrollTo} className="plans-wrapper position-relative u-pricing-v2-wrapper z-index-2">
        <div className="container u-space-4">
          {/* <!-- SubTitle --> */}
          <div className="w-md-80 w-lg-50 text-center mx-auto mb-9">
            <h2 className="jd-h2-titles">
              {" "}
              {data.firstSubtitle} <span className="font-weight-bold">{data.mainSubtitle}</span>
            </h2>
          </div>
          {/* <!-- End SubTitle --> */}
          <article>
            <div className="jd-table-planos-mobile">
              <ul className="price">
                {plansContent.map((plan, i) => {
                  return plan.isRecommended ? (
                    <li key={i} className={"bg-dark-blue" + (this.state.isActiveTab === plan.name ? " active" : "")}>
                      <button onClick={() => this.handleClick(plan.name)}>{plan.name}</button>
                    </li>
                  ) : (
                    <li key={i} className={"bg-blue" + (this.state.isActiveTab === plan.name ? " active" : "")}>
                      <button onClick={() => this.handleClick(plan.name)}>{plan.name}</button>
                    </li>
                  )
                })}
              </ul>

              <table className="table table-borderless" id="jd-compare-plans-table">
                <thead>
                  <tr>
                    <th className="hide" aria-label="Pricing table column"></th>
                    {plansContent.map((plan, i) => {
                      return plan.isRecommended ? (
                        <th key={i} className="jd-table-td jd-tag default">
                          recommended
                        </th>
                      ) : (
                        <th key={i} className="jd-table-td bg-blue" aria-label="Plan column"></th>
                      )
                    })}
                  </tr>

                  <tr>
                    <th scope="col" aria-label="Plan column"></th>
                    {plansContent.map((plan, i) => {
                      return (
                        <th key={i} scope="col" className="jd-table-th">
                          <img
                            className="img-fluid"
                            style={{ padding: "5px" }}
                            width="47%"
                            src={
                              plan.name === "Beach"
                                ? planImages[0].svg
                                : plan.name === "Sea"
                                ? planImages[1].svg
                                : planImages[2].svg
                            }
                            alt="Plan icon"
                          />
                          <br />
                          <span className="jd-plans-header">{plan.name}</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <br />
                    </td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {data.plans.currency}{" "}
                          {plan.isRecommended ? (
                            <span className=" jd-plans-prices" style={{ color: "#2b3e8d" }}>
                              {plan.price}
                            </span>
                          ) : (
                            <span className="jd-plans-prices">{plan.price}</span>
                          )}{" "}
                          /month
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td>
                      <br />
                    </td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          <a
                            className="btn jd-btn-primary--air u-btn-wide--sm rounded-pill vrlps-trigger"
                            href={plan.ourPlansLink}
                          >
                            {plan.ourPlansText}
                          </a>
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Unlimited graphic design</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.unlimitedGraphicDesign ? "Yes" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Unlimited web design</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.unlimitedWebDesign ? "Yes" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Unlimited revisions</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.revisions ? "Yes" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Brands</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.brands}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Users</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.users}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Rights included</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.rightsIncluded ? "Yes" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Turnaround</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.turnaround_short}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Dedicated designer</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.dedicatedDesigner ? "Yes" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Dedicated web designer</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.dedicatedWebDesigner ? "Yes" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Dedicated account manager</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.dedicatedAccountManager ? "Yes" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td colSpan={this.state.colSpan} className="sep">
                      Business and Advertising
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Banner</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.banner ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Identification badge</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.identificationBadge ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Display</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.display ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Simple photo editing</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.simplePhotoEditing ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Folder</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.folder ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Gift card</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.giftCard ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Booklet</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.booklet ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Restaurant menu</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.restaurantMenu ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Pamphlet and brochure</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.pamphletBrochure ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Poster</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.poster ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Signalling</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.signalling ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Internal communication</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.internalCommunication ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Simple illustration</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.simpleIllustration ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Apple Keynote template</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.keynoteTemplate ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Microsoft PowerPoint template</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.powerpointTemplate ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Microsoft Word template</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.wordTemplate ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Report design</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.reportDesign ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Catalog</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.catalog ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td colSpan={this.state.colSpan} className="sep">
                      Stationery System
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Sticker and label</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.adhesiveLabel ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Stamp</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.stamp ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Greeting card</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.giftCardPresent ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Postcard</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.postCard ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Business card</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.businessCard ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Invitation</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.invitation ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Envelope</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.envelope ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Letterhead</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.letterhead ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td colSpan={this.state.colSpan} className="sep">
                      Web-design &amp; Media
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Website Banner</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.webBanner ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Facebook cover</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.facebookCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Linkedin cover</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.linkedinCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Twitter cover</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.twitterCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Youtube channel cover</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.youtubeCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Blog post image</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.postBlog ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Social media post image</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.postSocialNetworks ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Form</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.form ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Icon design</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.iconDesign ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Button design</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.buttonDesign ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Mobile app icon</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.appsIcon ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Landing page design</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.landingPage ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Website icons</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.iconsWebsite ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">E-mail marketing layout</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.emailMarketing ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td colSpan={this.state.colSpan} className="sep">
                      Promotional Items
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Apron</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.apron ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Cap</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.cap ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">T-shirt</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.tShirt ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Cup and mug</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.cupMug ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Backpack and bag</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.BackpackBag ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Hoodie</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.pulloverShirt ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Uniform</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.uniform ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td colSpan={this.state.colSpan} className="sep">
                      Logo &amp; Identity
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Print</td>
                    {data.logoIdentity.map((logo, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === logo.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {logo.print ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Simple logo</td>
                    {data.logoIdentity.map((logo, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === logo.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {logo.simpleLogo ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Style guide</td>
                    {data.logoIdentity.map((logo, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === logo.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {logo.styleGuide ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Visual identity</td>
                    {data.logoIdentity.map((logo, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === logo.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {logo.visualIdentity ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Logo and brand</td>
                    {data.logoIdentity.map((logo, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === logo.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {logo.logoBrand ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td colSpan={this.state.colSpan} className="sep">
                      Label &amp; Packaging
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Standard packaging</td>
                    {data.labelPackaging.map((label, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === label.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {label.standardPacking ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Standard label</td>
                    {data.labelPackaging.map((label, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === label.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {label.standardLabel ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td colSpan={this.state.colSpan} className="sep">
                      Books &amp; magazines
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Book cover</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.bookCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Magazine cover</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.magazineCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">EBook cover</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.ebookCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Simple infographic</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.simpleInfographic ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Complex infographic</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.complexInfographic ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Book layout</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.bookInterior ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Magazine layout</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.magazineInterior ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td colSpan={this.state.colSpan} className="sep">
                      Art &amp; Illustrations
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Invitation illustration</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.invitation ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Website illustration</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.website ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Complex photo editing</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.photoEdit ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Corporate illustration</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.corporate ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Book illustration</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.book ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Magazine illustration</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.magazine ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
        </div>
      </div>
    )
  }
}
