import React from "react"

import Layout from "../layouts/en"
import SEO from "../components/SEO"
import Metomic from "../components/Metomic"
import SplashScreens from "../components/SplashScreens"
import Header from "../components/Header.en"
import PlansFeatures from "../components/PlansFeatures.en"

//Content
import plans from "../content/Plans-en.json"

const planImages = [
  { svg: require("../assets/svg/flat-icons/jd-icons-plans-star.svg") },
  { svg: require("../assets/svg/flat-icons/jd-icons-plans-fish.svg") },
  { svg: require("../assets/svg/flat-icons/jd-icons-plans-jelly-blue.svg") },
]

const businessAdvertisingContent = [
  {
    name: plans.beachPlanContent.name,
    banner: true,
    identificationBadge: true,
    display: true,
    simplePhotoEditing: true,
    folder: true,
    giftCard: true,
    booklet: true,
    restaurantMenu: true,
    pamphletBrochure: true,
    poster: true,
    signalling: true,
    internalCommunication: false,
    simpleIllustration: false,
    keynoteTemplate: false,
    powerpointTemplate: false,
    wordTemplate: false,
    reportDesign: false,
    catalog: false,
  },
  {
    name: plans.seaPlanContent.name,
    banner: true,
    identificationBadge: true,
    display: true,
    simplePhotoEditing: true,
    folder: true,
    giftCard: true,
    booklet: true,
    restaurantMenu: true,
    pamphletBrochure: true,
    poster: true,
    signalling: true,
    internalCommunication: true,
    simpleIllustration: true,
    keynoteTemplate: true,
    powerpointTemplate: true,
    wordTemplate: true,
    reportDesign: true,
    catalog: false,
  },
  {
    name: plans.oceanPlanContent.name,
    banner: true,
    identificationBadge: true,
    display: true,
    simplePhotoEditing: true,
    folder: true,
    giftCard: true,
    booklet: true,
    restaurantMenu: true,
    pamphletBrochure: true,
    poster: true,
    signalling: true,
    internalCommunication: true,
    simpleIllustration: true,
    keynoteTemplate: true,
    powerpointTemplate: true,
    wordTemplate: true,
    reportDesign: true,
    catalog: true,
  },
]

const stationarySystemContent = [
  {
    name: plans.beachPlanContent.name,
    adhesiveLabel: true,
    stamp: true,
    giftCardPresent: true,
    postCard: true,
    businessCard: true,
    invitation: true,
    envelope: true,
    letterhead: true,
  },
  {
    name: plans.seaPlanContent.name,
    adhesiveLabel: true,
    stamp: true,
    giftCardPresent: true,
    postCard: true,
    businessCard: true,
    invitation: true,
    envelope: true,
    letterhead: true,
  },
  {
    name: plans.oceanPlanContent.name,
    adhesiveLabel: true,
    stamp: true,
    giftCardPresent: true,
    postCard: true,
    businessCard: true,
    invitation: true,
    envelope: true,
    letterhead: true,
  },
]

const webDesignMediaContent = [
  {
    name: plans.beachPlanContent.name,
    webBanner: true,
    facebookCover: true,
    linkedinCover: true,
    twitterCover: true,
    youtubeCover: true,
    postBlog: true,
    postSocialNetworks: true,
    form: false,
    iconDesign: false,
    buttonDesign: false,
    appsIcon: false,
    landingPage: false,
    iconsWebsite: false,
    emailMarketing: false,
  },
  {
    name: plans.seaPlanContent.name,
    webBanner: true,
    facebookCover: true,
    linkedinCover: true,
    twitterCover: true,
    youtubeCover: true,
    postBlog: true,
    postSocialNetworks: true,
    form: true,
    iconDesign: true,
    buttonDesign: true,
    appsIcon: true,
    landingPage: false,
    iconsWebsite: false,
    emailMarketing: false,
  },
  {
    name: plans.oceanPlanContent.name,
    webBanner: true,
    facebookCover: true,
    linkedinCover: true,
    twitterCover: true,
    youtubeCover: true,
    postBlog: true,
    postSocialNetworks: true,
    form: true,
    iconDesign: true,
    buttonDesign: true,
    appsIcon: true,
    landingPage: true,
    iconsWebsite: true,
    emailMarketing: true,
  },
]

const promotionalClothingContent = [
  {
    name: plans.beachPlanContent.name,
    apron: true,
    cap: true,
    tShirt: true,
    cupMug: true,
    BackpackBag: true,
    pulloverShirt: true,
    uniform: true,
  },
  {
    name: plans.seaPlanContent.name,
    apron: true,
    cap: true,
    tShirt: true,
    cupMug: true,
    BackpackBag: true,
    pulloverShirt: true,
    uniform: true,
  },
  {
    name: plans.oceanPlanContent.name,
    apron: true,
    cap: true,
    tShirt: true,
    cupMug: true,
    BackpackBag: true,
    pulloverShirt: true,
    uniform: true,
  },
]

const logoIdentityContent = [
  {
    name: plans.beachPlanContent.name,
    print: true,
    simpleLogo: false,
    styleGuide: false,
    visualIdentity: false,
    logoBrand: false,
  },
  {
    name: plans.seaPlanContent.name,
    print: true,
    simpleLogo: true,
    styleGuide: false,
    visualIdentity: false,
    logoBrand: false,
  },
  {
    name: plans.oceanPlanContent.name,
    print: true,
    simpleLogo: true,
    styleGuide: true,
    visualIdentity: true,
    logoBrand: true,
  },
]

const labelPackagingContent = [
  {
    name: plans.beachPlanContent.name,
    standardPacking: false,
    standardLabel: false,
    customizedPackaging: false,
    customLabel: false,
  },
  {
    name: plans.seaPlanContent.name,
    standardPacking: true,
    standardLabel: true,
    customizedPackaging: false,
    customLabel: false,
  },
  {
    name: plans.oceanPlanContent.name,
    standardPacking: true,
    standardLabel: true,
    customizedPackaging: true,
    customLabel: true,
  },
]

const booksMagazinesContent = [
  {
    name: plans.beachPlanContent.name,
    bookCover: false,
    magazineCover: false,
    ebookCover: false,
    simpleInfographic: false,
    complexInfographic: false,
    bookInterior: false,
    magazineInterior: false,
  },
  {
    name: plans.seaPlanContent.name,
    bookCover: true,
    magazineCover: true,
    ebookCover: true,
    simpleInfographic: true,
    complexInfographic: false,
    bookInterior: false,
    magazineInterior: false,
  },
  {
    name: plans.oceanPlanContent.name,
    bookCover: true,
    magazineCover: true,
    ebookCover: true,
    simpleInfographic: true,
    complexInfographic: true,
    bookInterior: true,
    magazineInterior: true,
  },
]

const artIllustrationsContent = [
  {
    name: plans.beachPlanContent.name,
    invitation: true,
    website: false,
    photoEdit: false,
    corporate: false,
    book: false,
    magazine: false,
  },
  {
    name: plans.seaPlanContent.name,
    invitation: true,
    website: true,
    photoEdit: false,
    corporate: false,
    book: false,
    magazine: false,
  },
  {
    name: plans.oceanPlanContent.name,
    invitation: true,
    website: true,
    photoEdit: true,
    corporate: true,
    book: true,
    magazine: true,
  },
]

const PricingPage = (props) => (
  <Layout location={props.location}>
    <Metomic />
    <SEO
      title="Pricing and Plans | Jubarte Design"
      locale="en"
      altLocale="pt"
      pathname="/pricing/"
      description="Jubarte Design has affordable monthly plans to handle all your graphic and web design needs. Compare services by plan and choose the best for you."
    />
    <SplashScreens />
    <Header showLanguageSelector />
    <div className="position-relative text-center u-space-3-top">
      <div className="container u-space-3-top u-space-2-top--md u-space-0-bottom pricing">
        <PlansFeatures
          firstSubtitle="Compare"
          mainSubtitle="Services by Plan"
          plans={plans}
          planImages={planImages}
          bussinessAdvertising={businessAdvertisingContent}
          stationarysystem={stationarySystemContent}
          webDesignMedia={webDesignMediaContent}
          promotionalClothing={promotionalClothingContent}
          logoIdentity={logoIdentityContent}
          labelPackaging={labelPackagingContent}
          booksMagazines={booksMagazinesContent}
          artIllustrations={artIllustrationsContent}
        />
      </div>
    </div>
  </Layout>
)

export default PricingPage
